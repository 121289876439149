export let InvoiceTabs;
(function (InvoiceTabs) {
    InvoiceTabs["PENDING"] = "pending";
    InvoiceTabs["CLOSED"] = "closed";
    InvoiceTabs["ALL"] = "all";
})(InvoiceTabs || (InvoiceTabs = {}));
export let INVOICE_STATUS;
(function (INVOICE_STATUS) {
    INVOICE_STATUS["ALL"] = "all";
    INVOICE_STATUS["PENDING"] = "pending";
    INVOICE_STATUS["CLOSED"] = "closed";
})(INVOICE_STATUS || (INVOICE_STATUS = {}));
export let INVOICE_TYPE;
(function (INVOICE_TYPE) {
    INVOICE_TYPE["INVOICE"] = "invoice";
    INVOICE_TYPE["CREDIT_NOTE"] = "credit note";
})(INVOICE_TYPE || (INVOICE_TYPE = {}));
