import { goTo } from '@utils/helpers/userHelpers';
export let ProductTagTypes;
(function (ProductTagTypes) {
    ProductTagTypes[ProductTagTypes["NEW"] = 0] = "NEW";
    ProductTagTypes[ProductTagTypes["DISCOUNT"] = 1] = "DISCOUNT";
    ProductTagTypes[ProductTagTypes["DISCOUNT_AUCTION"] = 2] = "DISCOUNT_AUCTION";
})(ProductTagTypes || (ProductTagTypes = {}));
export let DeliveryTypes;
(function (DeliveryTypes) {
    DeliveryTypes[DeliveryTypes["PREMIUM"] = 0] = "PREMIUM";
    DeliveryTypes[DeliveryTypes["EXPRESS"] = 1] = "EXPRESS";
})(DeliveryTypes || (DeliveryTypes = {}));
export let ProductModes;
(function (ProductModes) {
    ProductModes[ProductModes["MICRO"] = 0] = "MICRO";
    ProductModes[ProductModes["MINI"] = 1] = "MINI";
    ProductModes[ProductModes["REGULAR"] = 2] = "REGULAR";
    ProductModes[ProductModes["THICK"] = 3] = "THICK";
    ProductModes[ProductModes["LARGE"] = 4] = "LARGE";
    ProductModes[ProductModes["LINEAR"] = 5] = "LINEAR";
})(ProductModes || (ProductModes = {}));
export let PriceMode;
(function (PriceMode) {
    PriceMode[PriceMode["REGULAR"] = 0] = "REGULAR";
    PriceMode[PriceMode["LINEAR"] = 1] = "LINEAR";
    PriceMode[PriceMode["LARGE"] = 2] = "LARGE";
    PriceMode[PriceMode["REGULAR_DETAILS"] = 3] = "REGULAR_DETAILS";
})(PriceMode || (PriceMode = {}));
export const goToArticle = (url, isDragging) => {
    if (isDragging) {
        return;
    }
    goTo(url);
};
export let INCREMENT_MODIFIER;
(function (INCREMENT_MODIFIER) {
    INCREMENT_MODIFIER[INCREMENT_MODIFIER["SMALL"] = 1] = "SMALL";
    INCREMENT_MODIFIER[INCREMENT_MODIFIER["MEDIUM"] = 10] = "MEDIUM";
    INCREMENT_MODIFIER[INCREMENT_MODIFIER["BIG"] = 100] = "BIG";
})(INCREMENT_MODIFIER || (INCREMENT_MODIFIER = {}));
