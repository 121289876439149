function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null)
    return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr))
    return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
import { PriceUnitName, BreadCrumbRoots, GeneralStrings } from '@utils/defines/index';
import moment from 'moment';
import { ProductModes } from '@components/productItem/defines';
import queryString from 'query-string';
import i18next from 'i18next';
import { getPages } from '@app/services/pages';
import { getProducts, getProductPrices as getProductPricesService } from '@app/services/product';
import { getCookieValue, setCookieValue } from './storage';
import { getUser } from './userHelpers';
import keycloak from './keycloak';
export const getImageUrlBySize = function (imageUrl, productMode) {
    if (imageUrl === void 0) {
        imageUrl = '';
    }
    if (!imageUrl || imageUrl.length === 0) {
        return require('@images/no-camera.svg');
    }
    switch (productMode) {
        case ProductModes.MINI:
            return imageUrl + "?d=180x130";
        // New new resize specific by lambda script
        case ProductModes.REGULAR:
            return imageUrl + "?d=180x130";
        case ProductModes.THICK:
            return imageUrl + "?d=275x186";
        case ProductModes.LINEAR:
            return imageUrl + "?d=320x320";
        case ProductModes.LARGE:
            return imageUrl + "?d=320x320";
        case ProductModes.MICRO:
            return imageUrl + "?d=52x52";
        default:
            return imageUrl;
    }
};
export const getImageUrlBySizeX2 = function (imageUrl, productMode) {
    if (imageUrl === void 0) {
        imageUrl = '';
    }
    if (!imageUrl || imageUrl.length === 0) {
        return require('@images/no-camera.svg');
    }
    switch (productMode) {
        case ProductModes.MINI:
            return imageUrl + "?d=500x372";
        // New new resize specific by lambda script
        case ProductModes.REGULAR:
            return imageUrl + "?d=500x372";
        case ProductModes.THICK:
            return imageUrl + "?d=550x372";
        case ProductModes.LINEAR:
            return imageUrl + "?d=640x640";
        case ProductModes.LARGE:
            return imageUrl + "?d=640x640";
        case ProductModes.MICRO:
            return imageUrl + "?d=104x104";
        default:
            return imageUrl;
    }
};
export const unitsArray = [{
        id: 0,
        name: 'N/A'
    }, {
        id: 1,
        name: PriceUnitName.PIECE
    }, {
        id: 2,
        name: PriceUnitName.BUNCH,
        quantity: 5
    }, {
        id: 3,
        name: PriceUnitName.BOX,
        quantity: 10
    }, {
        id: 4,
        name: PriceUnitName.LAYER,
        quantity: 15
    }, {
        id: 5,
        name: PriceUnitName.TROLLY,
        quantity: 20
    }];
export const getProductProperty = (properties, propertyId) => {
    const data = properties.filter(item => item.static_id === propertyId)[0];
    return data ? data.value : 'N/A';
};
export const convertPricingUnit = (amount, fromType, toType) => {
    if (fromType === toType) {
        return amount;
    }
    if (fromType === 1) {
        if (!unitsArray[toType].quantity) {
            return amount;
        }
        return amount * unitsArray[toType].quantity;
    }
    if (toType > fromType) {
        return amount * (unitsArray[toType].quantity / unitsArray[fromType].quantity);
    }
};
export const convertQuantity = (quantity, fromUnit, toUnit) => {
    if (!unitsArray[fromUnit] || !unitsArray[toUnit] || fromUnit === 1) {
        return quantity;
    }
    const quantityToConvertTo = unitsArray.filter(qt => qt.id === toUnit)[0];
    if (quantityToConvertTo.id === 1) {
        return quantity * unitsArray[fromUnit].quantity;
    }
    else if (toUnit > fromUnit) {
        return quantity * (unitsArray[toUnit].quantity / unitsArray[fromUnit].quantity);
    }
    return quantity;
};
export const getUnitName = unitId => {
    switch (unitId) {
        case 1:
            return PriceUnitName.PIECE;
        case 2:
            return PriceUnitName.BUNCH;
        case 3:
            return PriceUnitName.BOX;
        case 4:
            return PriceUnitName.LAYER;
        case 5:
            return PriceUnitName.TROLLY;
        default:
            return 'N/A';
    }
};
export const parseAvailableUnits = (minQtUnitId, maxQtUnitId) => {
    if (minQtUnitId === 0 || maxQtUnitId === 0) {
        return [];
    }
    const tempUnitsArray = [];
    for (let i = minQtUnitId; i <= maxQtUnitId; i++) {
        tempUnitsArray.push(unitsArray[i]);
    }
    return tempUnitsArray;
};
export const getUnitQuantity = id => {
    if (!id) {
        return 1;
    }
    return id === 1 ? 1 : unitsArray[id].quantity;
};
export const getEarliestDate = item => {
    if (item.length === 0 || !item) {
        return 'N/A';
    }
    let earliestDate = item[0];
    item.forEach(item => {
        if (moment(item).toDate() < moment(earliestDate).toDate()) {
            earliestDate = item;
        }
    });
    return earliestDate;
};
export const sortedByDates = items => {
    const sortedItems = _toConsumableArray(items);
    sortedItems.sort((a, b) => {
        return new Date(getEarliestDate(b.attributes.delivery_dates)).getTime() - new Date(getEarliestDate(a.attributes.delivery_dates)).getTime();
    });
    return sortedItems;
};
export const getMaximumDate = item => {
    if (item.length === 0 || !item) {
        return 'N/A';
    }
    let maximumDate = '1452-04-15';
    item.forEach(item => {
        if (moment(item).toDate() > moment(maximumDate).toDate()) {
            maximumDate = item;
        }
    });
    return maximumDate;
};
export const getDateGreaterThan = (datesArray, datePicked) => {
    const tempArray = datesArray.filter(item => moment(item).toDate() > moment(datePicked).toDate());
    const removeDuplicates = [];
    tempArray.forEach(item => {
        if (!removeDuplicates.includes(item)) {
            removeDuplicates.push(item);
        }
    });
    return removeDuplicates;
};
export const parseBreadCrumbName = name => {
    if (name.includes(BreadCrumbRoots.COMMERCIAL)) {
        return name.split(BreadCrumbRoots.COMMERCIAL)[1];
    }
    else if (name.includes(BreadCrumbRoots.FLORICODE)) {
        return name.split(BreadCrumbRoots.FLORICODE)[1];
    }
    return name;
};
export const parseBreadCrumbLink = link => {
    if (link.includes('floricode/')) {
        return link.replace('floricode/', '/');
    }
    if (link.includes('commercial/')) {
        return link.replace('commercial/', '/');
    }
    return link;
};
export const parseBreadCrumb = (breadCrumb, type) => {
    if (!breadCrumb || breadCrumb.length === 0) {
        return 'Home';
    }
    switch (type) {
        case 'name':
            return parseBreadCrumbName(breadCrumb).split('->');
        case 'link':
            return parseBreadCrumbLink(breadCrumb).split('/');
        default:
            break;
    }
    return breadCrumb;
};
export const handleMainMenuItems = async () => {
    return new Promise((resolve, reject) => {
        try {
            getUser().then(async (user) => {
                const response = await getPages((user === null || user === void 0 ? void 0 : user.user_type) == 'bulk');
                if (response.status === 503) {
                    return;
                }
                if (response.data.data) {
                    const { data: { data: { pages } } } = response;
                    localStorage.setItem('pages', JSON.stringify(pages));
                    localStorage.setItem('pages-created-at', JSON.stringify(moment().toDate()));
                    resolve(pages);
                }
            });
        }
        catch (error) {
            reject(error);
        }
    });
};
export const checkForUpates = () => {
    return new Promise(async (resolve, reject) => {
        if (typeof localStorage !== 'undefined') {
            if (localStorage.getItem('pages') && localStorage.getItem('pages-created-at')) {
                const pagesCreatedAt = moment(JSON.parse(localStorage.getItem('pages-created-at')));
                resolve(moment().subtract(pagesCreatedAt.toDate()).toDate().getMinutes() >= 15);
            }
            else {
                resolve(true);
            }
        }
    });
};
export const formatDateDisplay = date => {
    return moment(date).format('DD-MM-YY');
};
export const parsePNObject = pnObject => {
    const pnArray = [];
    if (!pnObject) {
        return pnArray;
    }
    if (pnObject.p1) {
        pnArray.push(pnObject.p1);
    }
    if (pnObject.p2) {
        pnArray.push(pnObject.p2);
    }
    return pnArray;
};
export const requestProducts = function (size, from, filters, location, options) {
    if (filters === void 0) {
        filters = {};
    }
    if (options === void 0) {
        options = {};
    }
    const result = new Promise((resolve, reject) => {
        try {
            delete filters.keyword;
            const params = {
                size,
                ...filters
            };
            let fromIndex = undefined;
            if (options.initialRequest && location.search.length) {
                const querystring = queryString.parse(location.search);
                if (querystring.search) {
                    filters = JSON.parse(querystring.search);
                    fromIndex = filters && filters['from'] ? filters['from'] : from;
                }
            }
            params['from'] = fromIndex ? parseInt(fromIndex, 10) : from;
            const tempTaxon = [];
            if (filters.taxon_slugs) {
                filters.taxon_slugs.forEach(item => {
                    tempTaxon.push(item);
                });
            }
            else if (options.taxonSlug && (!options.pageId || options.pageId < 1)) {
                tempTaxon.push("commercial/" + options.taxonSlug);
            }
            if (tempTaxon && tempTaxon.length !== 0) {
                params.taxon_slugs = tempTaxon;
            }
            if (options.includeTaxons) {
                params.include_taxons = true;
            }
            if (options.pageId !== -1 && options.pageId !== undefined) {
                params.page_id = options.pageId;
                if (options.initialRequest && params.taxon_slugs) {
                    delete params.taxon_slugs;
                }
            }
            if (location.search.includes('keyword=')) {
                const query_string = queryString.parse(location.search);
                if (params.include_taxons) {
                    delete params.include_taxons;
                }
                if (query_string.keyword) {
                    params.product_name = query_string.keyword;
                }
            }
            if (location.pathname.includes('/m/')) {
                params.module_id = location.pathname.split('/m/')[1];
                if (params.taxon_slugs) {
                    params.taxon_slugs = params.taxon_slugs.filter(slug => !slug.includes('commercial/m/'));
                }
            }
            getProducts({
                params
            }).then(response => {
                if (response.status === 503) {
                    return;
                }
                if (process.env.GROUPING_PRODUCTS == 'true') {
                    const products = response.data.products.map((product, index) => {
                        var _product$group;
                        return {
                            key: "product-item-" + (((_product$group = product.group) === null || _product$group === void 0 ? void 0 : _product$group.length) > 0 ? product.group[0].id : index.toString()),
                            ...product
                        };
                    });
                    response.data.products = products;
                }
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
    return result;
};
export const getCategoryName = location => {
    const category = location.pathname.split('/c/');
    return category[1];
};
export const handleProductStatusText = (inStock, totalOnHand, minOrderableQty, deliveryDates) => {
    if (totalOnHand < minOrderableQty) {
        return GeneralStrings.SOLD_OUT;
    }
    if (!inStock) {
        if (totalOnHand > minOrderableQty) {
            return GeneralStrings.EXPIRED;
        }
        return GeneralStrings.SOLD_OUT;
    }
    if (!deliveryDates || (deliveryDates === null || deliveryDates === void 0 ? void 0 : deliveryDates.length) === 0) {
        return GeneralStrings.EXPIRED;
    }
    return null;
};
export const updateCounts = function (newArray, oldArray, patch) {
    if (patch === void 0) {
        patch = true;
    }
    if (!patch) {
        return newArray;
    }
    const results = newArray;
};
export const updateFeatures = function (newfeatures, initFeatures, patch) {
    var _newfeatures$colors, _newfeatures$origins;
    if (patch === void 0) {
        patch = true;
    }
    const resultFeatures = initFeatures;
    if ((_newfeatures$colors = newfeatures.colors) === null || _newfeatures$colors === void 0 ? void 0 : _newfeatures$colors.length) {
        var _resultFeatures$color;
        const resultColors = [];
        (_resultFeatures$color = resultFeatures.colors) === null || _resultFeatures$color === void 0 ? void 0 : _resultFeatures$color.forEach(color => {
            const modifiedColor = newfeatures.colors.find(_color => _color.name === color.name);
            if (modifiedColor) {
                color.count = modifiedColor.count;
                resultColors.push(color);
            }
            else {
                color.count = 0;
                resultColors.push(color);
            }
        });
        resultFeatures.colors = [].concat(resultColors);
    }
    else {
        resultFeatures.colors = resultFeatures.colors.map(color => {
            color.count = 0;
            return color;
        });
    }
    resultFeatures.delivery_dates = initFeatures.delivery_dates;
    if ((_newfeatures$origins = newfeatures.origins) === null || _newfeatures$origins === void 0 ? void 0 : _newfeatures$origins.length) {
        var _resultFeatures$origi;
        const resultOrigins = [];
        (_resultFeatures$origi = resultFeatures.origins) === null || _resultFeatures$origi === void 0 ? void 0 : _resultFeatures$origi.forEach(origin => {
            const modifiedOrigin = newfeatures.origins.find(_origin => _origin.name === origin.name);
            if (modifiedOrigin) {
                origin.count = modifiedOrigin.count;
                resultOrigins.push(origin);
            }
            else {
                origin.count = 0;
                resultOrigins.push(origin);
            }
        });
        resultFeatures.origins = [].concat(resultOrigins);
    }
    else {
        var _resultFeatures$origi2;
        resultFeatures.origins = (_resultFeatures$origi2 = resultFeatures.origins) === null || _resultFeatures$origi2 === void 0 ? void 0 : _resultFeatures$origi2.map(origin => {
            origin.count = 0;
            return origin;
        });
    }
    if (newfeatures.slug) {
        resultFeatures.slug = newfeatures.slug;
    }
    if (newfeatures.stem_length) {
        resultFeatures.stem_length = {
            original_max_length: initFeatures.stem_length.original_max_length || initFeatures.stem_length.max_length,
            original_min_length: initFeatures.stem_length.original_min_length || initFeatures.stem_length.min_length,
            max_length: newfeatures.stem_length.max_length,
            min_length: newfeatures.stem_length.min_length
        };
    }
    if (newfeatures.suppliers) {
        var _resultFeatures$suppl;
        const resultSuppliers = [];
        (_resultFeatures$suppl = resultFeatures.suppliers) === null || _resultFeatures$suppl === void 0 ? void 0 : _resultFeatures$suppl.forEach(supplier => {
            const modifiedSupplier = newfeatures.suppliers.find(_supplier => _supplier.name === supplier.name);
            if (modifiedSupplier) {
                supplier.count = modifiedSupplier.count;
                resultSuppliers.push(supplier);
            }
            else {
                supplier.count = 0;
                resultSuppliers.push(supplier);
            }
        });
        resultFeatures.suppliers = [].concat(resultSuppliers);
    }
    else {
        var _resultFeatures$suppl2;
        resultFeatures.suppliers = (_resultFeatures$suppl2 = resultFeatures.suppliers) === null || _resultFeatures$suppl2 === void 0 ? void 0 : _resultFeatures$suppl2.map(supplier => {
            supplier.count = 0;
            return supplier;
        });
    }
    if (newfeatures.origin_suppliers) {
        var _resultFeatures$origi3;
        const resultOriginSuppliers = [];
        (_resultFeatures$origi3 = resultFeatures.origin_suppliers) === null || _resultFeatures$origi3 === void 0 ? void 0 : _resultFeatures$origi3.forEach(origin_supplier => {
            const modifiedOriginSupplier = newfeatures.origin_suppliers.find(_originSupplier => _originSupplier.name === origin_supplier.name);
            if (modifiedOriginSupplier) {
                origin_supplier.count = modifiedOriginSupplier.count;
                resultOriginSuppliers.push(origin_supplier);
            }
            else {
                origin_supplier.count = 0;
                resultOriginSuppliers.push(origin_supplier);
            }
        });
        resultFeatures.origin_suppliers = [].concat(resultOriginSuppliers);
    }
    else {
        var _resultFeatures$origi4;
        resultFeatures.origin_suppliers = (_resultFeatures$origi4 = resultFeatures.origin_suppliers) === null || _resultFeatures$origi4 === void 0 ? void 0 : _resultFeatures$origi4.map(originSupplier => {
            originSupplier.count = 0;
            return originSupplier;
        });
    }
    if (newfeatures.tag_list) {
        resultFeatures.tag_list = newfeatures.tag_list;
    }
    if (newfeatures.taxons) {
        var _resultFeatures$taxon;
        const resultTaxons = [];
        (_resultFeatures$taxon = resultFeatures.taxons) === null || _resultFeatures$taxon === void 0 ? void 0 : _resultFeatures$taxon.forEach(taxon => {
            const modifiedTaxon = newfeatures.taxons.find(_taxon => _taxon.name === taxon.name);
            if (modifiedTaxon) {
                taxon.count = modifiedTaxon.count;
                resultTaxons.push(taxon);
            }
            else {
                taxon.count = 0;
                resultTaxons.push(taxon);
            }
        });
        resultFeatures.taxons = [].concat(resultTaxons);
    }
    else {
        var _resultFeatures$taxon2;
        resultFeatures.taxons = (_resultFeatures$taxon2 = resultFeatures.taxons) === null || _resultFeatures$taxon2 === void 0 ? void 0 : _resultFeatures$taxon2.map(taxon => {
            taxon.count = 0;
            return taxon;
        });
    }
    if (newfeatures.total_on_hand) {
        resultFeatures.total_on_hand = newfeatures.total_on_hand;
    }
    return resultFeatures;
};
export const handleTranslation = (path, fallback) => {
    if (!path) {
        return fallback;
    }
    if (i18next.exists(path, {
        ns: 'translations'
    })) {
        return i18next.t(path, {
            ns: 'translations'
        });
    }
    return fallback;
};
export function parseFiltersToURI(filters) {
    const query = {};
    if (filters['q[s]']) {
        query['sort-by'] = filters['q[s]'].replace(' ', '-');
    }
    if (filters.keyword) {
        query['keyword'] = filters.keyword;
    }
    if (filters.delivery_date) {
        query['delivery-date'] = filters.delivery_date;
    }
    if (filters.product_name) {
        query['product-name'] = filters.product_name;
    }
    if (filters.taxon_slugs) {
        query['category'] = filters.taxon_slugs.map(slug => {
            const parts = slug.split('/').join('_');
            return parts;
        });
    }
    if (filters.suppliers) {
        query['supply'] = filters.suppliers;
    }
    if (filters.origin_suppliers) {
        query['origin_suppliers'] = filters.origin_suppliers;
    }
    if (filters.colors) {
        query.color = filters.colors;
    }
    if (filters['stem_length[max]']) {
        query['length-max'] = filters['stem_length[max]'];
    }
    if (filters['stem_length[min]']) {
        query['length-min'] = filters['stem_length[min]'];
    }
    if (filters.price_start) {
        query['price-start'] = filters.price_start;
    }
    if (filters.price_end) {
        query['price-end'] = filters.price_end;
    }
    if (filters.origins) {
        query.origin = filters.origins;
    }
    return queryString.stringify(query, {
        sort: false
    });
}
export function parsePaginationInfoToURI(paginationInfo) {
    const query = {};
    if (paginationInfo.currentPage > 0)
        query['page'] = paginationInfo.currentPage + 1;
    return queryString.stringify(query, {
        sort: false
    });
}
export function parseURIToFilters(search) {
    const query = queryString.parse(search);
    const filters = {};
    if (query['sort-by']) {
        filters['q[s]'] = query['sort-by'].replace('-', ' ');
    }
    if (query.keyword) {
        filters.keyword = query.keyword;
    }
    if (query.color) {
        filters.colors = Array.isArray(query.color) ? query.color : [query.color];
    }
    if (query['delivery-date']) {
        filters.delivery_date = query['delivery-date'];
    }
    if (query['category']) {
        const categories = Array.isArray(query['category']) ? query['category'] : [query['category']];
        filters.taxon_slugs = categories.map(slug => {
            return "" + slug.split('_').join('/');
        });
    }
    if (query.origin) {
        filters.origins = Array.isArray(query.origin) ? query.origin : [query.origin];
    }
    if (query['price-start']) {
        filters.price_start = query['price-start'];
    }
    if (query['price-end']) {
        filters.price_end = query['price-end'];
    }
    if (query['product-name']) {
        filters.product_name = query['product-name'];
    }
    if (query['length-max']) {
        filters['stem_length[max]'] = parseInt(query['length-max'], 10);
    }
    if (query['length-min']) {
        filters['stem_length[min]'] = parseInt(query['length-min'], 10);
    }
    if (query['supply']) {
        filters.suppliers = Array.isArray(query['supply']) ? query['supply'] : [query['supply']];
    }
    if (query['origin_suppliers']) {
        filters.origin_suppliers = Array.isArray(query['origin_suppliers']) ? query['origin_suppliers'] : [query['origin_suppliers']];
    }
    return filters;
}
export const parseURIToPaginationInfo = search => {
    const query = queryString.parse(search);
    const paginationInfo = {};
    if (query['page']) {
        paginationInfo.page = parseInt(query['page'], 10) - 1;
    }
    return paginationInfo;
};
export const getProductPrices = productId => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await getProductPricesService(productId);
            if (response.status === 503) {
                return;
            }
            resolve(response.data);
        }
        catch (error) {
            // @TODO handle error
            reject(null);
        }
    });
};
export const setUrlMapperCookie = urlType => {
    var _getCookieValue;
    const UrlMapperStr = (_getCookieValue = getCookieValue('url_mapper')) !== null && _getCookieValue !== void 0 ? _getCookieValue : '{}';
    const UrlMapper = JSON.parse(UrlMapperStr == '' ? '{}' : UrlMapperStr);
    UrlMapper['url_type'] = urlType;
    setCookieValue('url_mapper', JSON.stringify(UrlMapper));
};
export const getUrlMapperCookie = () => {
    var _getCookieValue2;
    const UrlMapperStr = (_getCookieValue2 = getCookieValue('url_mapper')) !== null && _getCookieValue2 !== void 0 ? _getCookieValue2 : '{}';
    const UrlMapper = JSON.parse(UrlMapperStr == '' ? '{}' : UrlMapperStr);
    return UrlMapper['url_type'];
};
export const clearUrlMapperCookie = () => {
    var _getCookieValue3;
    const UrlMapperStr = (_getCookieValue3 = getCookieValue('url_mapper')) !== null && _getCookieValue3 !== void 0 ? _getCookieValue3 : '{}';
    const UrlMapper = JSON.parse(UrlMapperStr == '' ? '{}' : UrlMapperStr);
    delete UrlMapper['url_type'];
    setCookieValue('url_mapper', JSON.stringify(UrlMapper));
};
export const getLastTagKey = tagKey => {
    const parts = tagKey.split('.');
    const result = parts[parts.length - 1];
    return result;
};
export function isProductAvailable(product, CartsInit) {
    var _product$attributes$p, _product$attributes, _product$inStock, _product$attributes2, _product$totalInHand, _product$attributes3, _product$attributes3$, _ref, _product$quantity, _product$attributes4;
    if (CartsInit === void 0) {
        CartsInit = false;
    }
    const prices = (_product$attributes$p = product === null || product === void 0 ? void 0 : (_product$attributes = product.attributes) === null || _product$attributes === void 0 ? void 0 : _product$attributes.prices[0]) !== null && _product$attributes$p !== void 0 ? _product$attributes$p : product === null || product === void 0 ? void 0 : product.price;
    const in_stock = (_product$inStock = product === null || product === void 0 ? void 0 : product.inStock) !== null && _product$inStock !== void 0 ? _product$inStock : product === null || product === void 0 ? void 0 : (_product$attributes2 = product.attributes) === null || _product$attributes2 === void 0 ? void 0 : _product$attributes2.in_stock;
    const total_on_hand = (_product$totalInHand = product === null || product === void 0 ? void 0 : product.totalInHand) !== null && _product$totalInHand !== void 0 ? _product$totalInHand : product === null || product === void 0 ? void 0 : (_product$attributes3 = product.attributes) === null || _product$attributes3 === void 0 ? void 0 : (_product$attributes3$ = _product$attributes3.product) === null || _product$attributes3$ === void 0 ? void 0 : _product$attributes3$.total_on_hand;
    const quantity = (_ref = (_product$quantity = product === null || product === void 0 ? void 0 : product.quantity) !== null && _product$quantity !== void 0 ? _product$quantity : product === null || product === void 0 ? void 0 : (_product$attributes4 = product.attributes) === null || _product$attributes4 === void 0 ? void 0 : _product$attributes4.quantity) !== null && _ref !== void 0 ? _ref : product === null || product === void 0 ? void 0 : product.quantityInCart;
    if (keycloak === null || keycloak === void 0 ? void 0 : keycloak.authenticated) {
        if (in_stock) {
            if (prices) {
                if (CartsInit) {
                    return total_on_hand >= quantity;
                }
                else {
                    return total_on_hand >= (prices === null || prices === void 0 ? void 0 : prices.minimum_orderable_quantity);
                }
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }
    else
        return true;
}
export function isProductExpired(product, CartsInit) {
    var _product$attributes$p2, _product$attributes5, _product$deliveryDate, _product$attributes6, _product$deliveryDate2, _product$attributes7, _product$inStock2, _product$attributes8, _product$totalInHand2, _product$attributes9, _product$attributes9$;
    if (CartsInit === void 0) {
        CartsInit = false;
    }
    const prices = (_product$attributes$p2 = product === null || product === void 0 ? void 0 : (_product$attributes5 = product.attributes) === null || _product$attributes5 === void 0 ? void 0 : _product$attributes5.prices[0]) !== null && _product$attributes$p2 !== void 0 ? _product$attributes$p2 : product === null || product === void 0 ? void 0 : product.price; // master.prices
    const delivery_date = (_product$deliveryDate = product === null || product === void 0 ? void 0 : product.deliveryDate) !== null && _product$deliveryDate !== void 0 ? _product$deliveryDate : product === null || product === void 0 ? void 0 : (_product$attributes6 = product.attributes) === null || _product$attributes6 === void 0 ? void 0 : _product$attributes6.delivery_date;
    const delivery_dates = (_product$deliveryDate2 = product === null || product === void 0 ? void 0 : product.deliveryDates) !== null && _product$deliveryDate2 !== void 0 ? _product$deliveryDate2 : product === null || product === void 0 ? void 0 : (_product$attributes7 = product.attributes) === null || _product$attributes7 === void 0 ? void 0 : _product$attributes7.delivery_dates;
    const deliveryDate = !delivery_date && !delivery_dates || !delivery_date && (delivery_dates === null || delivery_dates === void 0 ? void 0 : delivery_dates.length) === 0;
    const in_stock = (_product$inStock2 = product === null || product === void 0 ? void 0 : product.inStock) !== null && _product$inStock2 !== void 0 ? _product$inStock2 : product === null || product === void 0 ? void 0 : (_product$attributes8 = product.attributes) === null || _product$attributes8 === void 0 ? void 0 : _product$attributes8.in_stock;
    const total_on_hand = (_product$totalInHand2 = product === null || product === void 0 ? void 0 : product.totalInHand) !== null && _product$totalInHand2 !== void 0 ? _product$totalInHand2 : product === null || product === void 0 ? void 0 : (_product$attributes9 = product.attributes) === null || _product$attributes9 === void 0 ? void 0 : (_product$attributes9$ = _product$attributes9.product) === null || _product$attributes9$ === void 0 ? void 0 : _product$attributes9$.total_on_hand;
    if (!in_stock) {
        if (prices) {
            if (CartsInit) {
                return total_on_hand > (prices === null || prices === void 0 ? void 0 : prices.minimum_orderable_quantity) || deliveryDate //master in cart
                ;
            }
            else {
                return total_on_hand > (prices === null || prices === void 0 ? void 0 : prices.minimum_orderable_quantity) || deliveryDate;
            }
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}
export const setCartMapperLocalStorage = (isMaster, data) => {
    if (isMaster) {
        if (localStorage.getItem('cart')) {
            var _JSON$parse;
            const cart = (_JSON$parse = JSON.parse(localStorage.getItem('cart'))) !== null && _JSON$parse !== void 0 ? _JSON$parse : [];
            const activeCartIndex = cart.findIndex(item => item.data.id === data.id);
            if (activeCartIndex >= 0) {
                cart[activeCartIndex] = {
                    data
                };
            }
            else {
                cart.push({
                    data
                });
            }
            localStorage.setItem('cart', JSON.stringify(cart));
        }
    }
    else {
        localStorage.setItem('cart', JSON.stringify(data));
    }
};
