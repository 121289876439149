import { getCookieValue, setCookieValue } from '../storage';
export const setEventMapperCookie = (action_name, needed_info) => {
    var _getCookieValue;
    const eventMapperStr = (_getCookieValue = getCookieValue('event_mapper')) !== null && _getCookieValue !== void 0 ? _getCookieValue : '{}';
    const eventMapper = JSON.parse(eventMapperStr == '' ? '{}' : eventMapperStr);
    eventMapper[action_name] = needed_info;
    setCookieValue('event_mapper', JSON.stringify(eventMapper));
};
export const getEventMapperCookie = action_name => {
    var _getCookieValue2;
    const eventMapperStr = (_getCookieValue2 = getCookieValue('event_mapper')) !== null && _getCookieValue2 !== void 0 ? _getCookieValue2 : '{}';
    const eventMapper = JSON.parse(eventMapperStr == '' ? '{}' : eventMapperStr);
    return eventMapper[action_name];
};
export const clearEventMapperCookie = action_name => {
    var _getCookieValue3;
    const eventMapperStr = (_getCookieValue3 = getCookieValue('event_mapper')) !== null && _getCookieValue3 !== void 0 ? _getCookieValue3 : '{}';
    const eventMapper = JSON.parse(eventMapperStr == '' ? '{}' : eventMapperStr);
    delete eventMapper[action_name];
    setCookieValue('event_mapper', JSON.stringify(eventMapper));
};
