export let UserWarningTypes;
(function (UserWarningTypes) {
    UserWarningTypes["USER_BLOCKED"] = "user_blocked";
    UserWarningTypes["USER_EXCEEDED_PURCHASE_LIMIT"] = "user_exceeded_purchase_limit";
})(UserWarningTypes || (UserWarningTypes = {}));
export let UserWarningMessages;
(function (UserWarningMessages) {
    UserWarningMessages["USER_BLOCKED_MESSAGE"] = "blocked_user_popover.your_timely_payment_note";
    UserWarningMessages["USER_EXCEEDED_PURCHASE_LIMIT_MESSAGE"] = "blocked_user_note.user_exceeded_purchase_limit_message";
    UserWarningMessages["MY_INVOICES"] = "blocked_user_popover.my_invoices";
    UserWarningMessages["PAY_NOW"] = "blocked_user_popover.pay_now";
})(UserWarningMessages || (UserWarningMessages = {}));
