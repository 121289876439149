import { FilterItemsTypes } from '@utils/defines/index';
export const autoCompleteItems = [{
        title: 'Flowers',
        id: 1000,
        categoryId: 1
    }, {
        title: 'Greeneries',
        id: 1001,
        categoryId: 2
    }, {
        title: 'Floral Foam',
        id: 1003,
        categoryId: 2
    }, {
        title: 'Florist Tape',
        id: 1004,
        categoryId: 3
    }, {
        title: 'Floss Flower',
        id: 1005,
        categoryId: 1
    }, {
        title: 'Flox Flower',
        id: 1006,
        categoryId: 1
    }, {
        title: 'Spray Rose Haley',
        id: 1007
    }, {
        title: 'Blue Flowers',
        id: 1008
    }, {
        title: 'Green Leaf Flowes',
        id: 1009
    }, {
        title: 'Blue Orchid',
        id: 1010,
        categoryId: 5
    }, {
        title: 'Red Orchid',
        id: 1011,
        categoryId: 6
    }, {
        title: 'Red Rose',
        id: 1012,
        categoryId: 5
    }];
export const filterItems = [{
        title: 'Search in',
        items: [[{
                    title: 'All Categories',
                    id: 1000,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX
                }], [{
                    title: 'Flowers',
                    id: 1001,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX,
                    alias: 'category'
                }, {
                    title: 'Roses',
                    id: 1002,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX,
                    alias: 'category'
                }, {
                    title: 'Plants',
                    id: 1003,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX,
                    alias: 'category'
                }, {
                    title: 'Greeneries',
                    id: 1004,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX,
                    alias: 'category'
                }, {
                    title: 'Accessories',
                    id: 1005,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX,
                    alias: 'category'
                }, {
                    title: 'Fillers',
                    id: 1006,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX,
                    alias: 'category'
                }]]
    }, {
        title: 'Delivery Option',
        items: [[{
                    title: 'Determined Delivery Date',
                    id: 1009,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX,
                    alias: 'delivery'
                }, {
                    title: '',
                    id: 1010,
                    type: FilterItemsTypes.CALENDAR
                }]]
    }, {
        title: 'Tags',
        items: [[{
                    title: 'Special Offer',
                    id: 1011,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX,
                    alias: 'tags'
                }, {
                    title: 'Fresh Sale',
                    id: 1012,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX,
                    alias: 'tags'
                }, {
                    title: 'In Auction',
                    id: 1013,
                    isChecked: false,
                    type: FilterItemsTypes.CHECKBOX,
                    alias: 'tags'
                }]]
    }, {
        title: 'Colors',
        items: [[{
                    title: 'Select your desired color',
                    id: 1014,
                    type: FilterItemsTypes.COLORPICKER,
                    alias: 'colors'
                }]]
    }];
