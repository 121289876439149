export let ActionName;
(function (ActionName) {
    ActionName["GLOBAL_SEARCH"] = "global_search";
    ActionName["APPLY_FILTERS"] = "apply_filters";
    ActionName["ADD_TO_CART"] = "add_to_cart";
    ActionName["REMOVE_FROM_CART"] = "remove_from_cart";
})(ActionName || (ActionName = {}));
export let Action;
(function (Action) {
    Action["CONTINUE_WITH_WEBSITE"] = "continue_with_website";
    Action["DOWNLOAD_APP"] = "download_app";
    Action["CLICK_ANNOUNCEMENT_BUTTON"] = "click_announcement_button";
    Action["CLOSE_ANNOUNCEMENT"] = "close_announcement";
    Action["ANNOUNCEMENT_AGREEMENT"] = "announcement_agreement";
    Action["ADD"] = "add";
})(Action || (Action = {}));
export let BadgeName;
(function (BadgeName) {
    BadgeName["GOOGLE_PLAY"] = "google_play";
    BadgeName["APP_GALLERY"] = "app_gallery";
    BadgeName["APP_STORE"] = "app_store";
})(BadgeName || (BadgeName = {}));
