import { Position, Toaster } from '@blueprintjs/core';
let AppToaster = {
    show: () => { }
};
if (typeof window !== 'undefined') {
    AppToaster = Toaster.create({
        className: 'app-toaster',
        position: Position.BOTTOM_LEFT
    });
}
export { AppToaster };
