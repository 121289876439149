import axios from 'axios';
export const getPage = async (pageId) => {
    return axios.get("/api/v1/pages/" + pageId).then(response => {
        return response;
    });
};
export const getPages = async (isBulk) => {
    const bulkParam = isBulk ? 'bulk=true' : '';
    return axios.get("/api/v1/pages?" + bulkParam).then(response => {
        return response;
    });
};
export const getHomePage = async (isBulk) => {
    const bulkParam = isBulk ? 'bulk=true' : '';
    return axios.get("/api/v1/pages/home_page?" + bulkParam).then(response => {
        return response;
    });
};
