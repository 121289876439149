import React from 'react';

const DollarSign = (props) => (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
            <defs>
                <style>
                    {
                        `
                    .a,.b{
                    fill: none;
                    stroke:${props.color};
                    stroke-miterlimit:10;
                    stroke-width:1.5px;
                    }.b{stroke - linecap:round;
                    }
                    `
                    } 
                </style>
            </defs>
            <g transform="translate(-795.989 -1119.771)">
                <circle className="a" cx="11.5" cy="11.5" r="11.5" transform="translate(796.739 1120.521)" />
                <g transform="translate(804.095 1124.424)">
                    <line className="b" y2="2.762" transform="translate(4.144 12.431)" />
                    <line className="b" y2="2.762" transform="translate(4.144)" />
                    <path className="b" d="M811.837,1127.332h-5.18a2.417,2.417,0,0,0,0,4.834h3.453a2.417,2.417,0,0,1,0,4.835h-5.87" transform="translate(-804.24 -1124.57)" />
                </g>
            </g>
        </svg>
    </>
);

export default DollarSign;
