import axios from 'axios';
export const getProducts = async function (body, isGroups) {
    if (isGroups === void 0) {
        isGroups = true;
    }
    if (isGroups && process.env.GROUPING_PRODUCTS == 'true') {
        return axios.get('/api/v1/products?groups=true', {
            ...body
        }).then(response => {
            return response;
        });
    }
    else {
        return axios.get('/api/v1/products', {
            ...body
        }).then(response => {
            return response;
        });
    }
};
export const getProduct = async (product_slug, countryCode) => {
    return axios.get("/api/v1/products/" + product_slug + "?country=" + countryCode).then(response => {
        return response;
    });
};
export const getAutoCompleteSearch = async (data) => {
    return axios.get("/api/v1/products/autocomplete?" + (process.env.GROUPING_PRODUCTS == 'true' ? 'groups=true' : ''), {
        ...data
    }).then(response => {
        return response;
    });
};
export const getProductPrices = async (productId) => {
    return axios.get("/api/v1/products/prices?product_id=" + productId).then(response => {
        return response;
    });
};
